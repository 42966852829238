import * as React from 'react';
import { LOCALES } from '../../../config';

export default function Text() {
  return (
    <div>
      <h1>{LOCALES.AUTH.H1}</h1>
      <p>
        {LOCALES.AUTH.P_1}
        <br />
        {LOCALES.AUTH.P_2}
      </p>
    </div>
  );
}
