import * as React from 'react';
import './styles/index.less';
import Authenticate from './authenticate';
// eslint-disable-next-line
import MainLayout from './main_layout';
import AppProps from './props';
// eslint-disable-next-line
import Firebase from '../config/firebase';
import MockLayout from './mock_layout';
/**
 * Application component.
 * Render `Authenticate` if the user is not logged in.
 *
 * The state is **not** stored here (!), see `MainLayout`.
 */
const App = ({ debug }: AppProps) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false); // TODO: Replace with JWT
  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    const firebase = new Firebase();
    firebase.onAuthStateChanged((user) => {
      if (user === null) {
        setIsAuthenticated(false); // Warning: do not use in production (!)
      } else {
        setIsAuthenticated(true); // Warning: do not use in production (!)
      }
    });
  }, []);
  return isAuthenticated ? (
    <MockLayout debug={debug} />
  ) : (
    <Authenticate setIsAuthenticated={setIsAuthenticated} />
  );
};

export default App;
