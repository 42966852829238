import * as React from 'react';
import Form from './form';
import * as Styles from './styles/index.module.less';
import AuthenticateProps from './props';
import SVGComponent from './waves';
import Text from './text';
import { IDENTIFIERS } from '../../config';

const BACKGROUND_URL: string =
  /* @__PURE__ */ '/static/img/backgrounds/background-01.jpg';
/**
 * `Login` or `Authenticate` component.
 * @deprecated TODO: Add `Firebase`
 */
export default function Authenticate({
  setIsAuthenticated,
}: AuthenticateProps) {
  /**
   * TODO: Check copyright.
   * Images are taken from
   * [https://www.vossloh-nordic.com/se/produkter-och-losningar/referensprojekt/11-nya-vaxlar-pa-39-timmar.html]
   * (https://www.vossloh-nordic.com/se/produkter-och-losningar/referensprojekt/11-nya-vaxlar-pa-39-timmar.html)
   */
  return (
    <>
      <title>Logga in | Vossloh Planner</title>
      <div
        id={IDENTIFIERS.PAGE_HEADER}
        className={Styles.pageHeader}
        style={{
          backgroundImage: `url(${BACKGROUND_URL})`, // N.B: Add background image dynamically (and not hard code in the LESS)
        }}
      >
        <span className={Styles.backgroundGradient} />
        <div className={Styles.container}>
          <Text />
          <Form
            formContainer={Styles.formContainer}
            form={Styles.form}
            setIsAuthenticated={setIsAuthenticated}
          />
        </div>
        <div className={Styles.footer}>
          <SVGComponent />
        </div>
      </div>
    </>
  );
}
