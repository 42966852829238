import * as React from 'react';
import { Button, Form as AntdForm, Input, message } from 'antd';
import FormProps from './props';
import { LOCALES } from '../../../config';
// eslint-disable-next-line
import Firebase from '../../../config/firebase';

const { Item } = AntdForm;

// @ts-ignore
// eslint-disable-next-line
String.prototype.hashCode = function () {
  // eslint-disable-next-line
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    // eslint-disable-next-line
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
/**
 * Form component.
 * @deprecated TODO: Replace with `Firebase` login.
 */
export default function Form({
  setIsAuthenticated,
  form,
  formContainer,
}: FormProps) {
  /**
   * Wrong password and/or username.
   */
  const onWrongCredentials = () => {
    message.error(LOCALES.AUTH.ERROR_MESSAGE);
  };
  /**
   * Both username and password is supplied.
   */
  const onFinish = async (values: any) => {
    if (typeof window === 'undefined') return;
    const { username, password, remember } = values;

    const firebase = new Firebase();
    const isAuth = await firebase.auth(username, password, remember);
    if (!isAuth) {
      onWrongCredentials();
      return;
    }

    setIsAuthenticated(true);
  };
  /**
   * Username and/or password is missing.
   */
  const onFinishFailed = (errorInfo: any) => {
    onWrongCredentials();
    // eslint-disable-next-line
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={formContainer}>
      <AntdForm
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: 'checked' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className={form}
      >
        <label>{LOCALES.AUTH.USERNAME}</label>
        <Item
          name="username"
          rules={[{ required: true, message: LOCALES.AUTH.WRONG_USERNAME }]}
          noStyle
        >
          <Input />
        </Item>
        <label>{LOCALES.AUTH.PASSWORD}</label>
        <Item
          name="password"
          rules={[{ required: true, message: LOCALES.AUTH.WRONG_USERNAME }]}
          noStyle
        >
          <Input.Password />
        </Item>
        {/* <summary />
        <Item name="remember" valuePropName="checked" noStyle>
          <Switch />
        </Item>{' '}
        <label>{LOCALES.AUTH.REMEMBER}</label> */}
        <summary />
        <Item wrapperCol={{ offset: 8, span: 16 }} noStyle>
          <Button block type="primary" htmlType="submit" shape="round">
            {LOCALES.AUTH.LOGIN}
          </Button>
          {/* <Button
            href="https://youtu.be/zr1m3d4sxwQ?t=12"
            target="_blank"
            style={{ marginTop: 10 }}
            block
            type="dashed"
            shape="round"
          >
            {LOCALES.AUTH.FORGOT}
          </Button> */}
        </Item>
      </AntdForm>
    </div>
  );
}
